import { notification } from "antd";
import { AxiosAPI } from "./api";

const ReportsApi = {
	GenerateCapitalAccount(data:any):Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosAPI.post(`api/v1/PDF/GenerateCapitalAccount`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
            .then((data) => { 
                if (data !== null) {
                    return resolve(data?.data);
                } else {
                    return reject(null);
                }
            });
        });
    },
    GenerateCapitalCall(data:any):Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosAPI.post(`/api/v1/PDF/GenerateCapitalCall`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
            .then((data) => { 
                if (data !== null) {
                    return resolve(data?.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    },
    GenerateDistributionNotice(data:any):Promise<any> {
        return new Promise((resolve, reject) => {
            AxiosAPI.post(`/api/v1/PDF/GenerateDistributionNotice`,data, {
                responseType: 'arraybuffer',
                headers: {
                  'Accept': 'application/pdf'
                }
            })
            .then((data) => { 
                if (data !== null) {
                    return resolve(data?.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    },
    GenerateClosing(idInvestor:number):Promise<any> {
        return new Promise((resolve, reject) => {
			AxiosAPI.get(`${process.env.REACT_APP_NEW_WEB_API}/documentsystem-api/Investor/GetCommitmentLetter?IdInvestor=${idInvestor}`)
            .then((data) => { 
                if (data !== null) {
                    return resolve(data?.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
				notification.error({message: 'Error', description: 'Error while generating the document'});
                return reject(error);
            });
        });
    },
    GetCapitalCallDates(data: any):Promise<any> {
        let idVehicle =  data.idVehicle;
        let investors = data.investors;
        let query = investors.map((investor:any) => `IdInvestors=${investor}`).join('&');
        return new Promise((resolve, reject) => {
            AxiosAPI.get(`${process.env.REACT_APP_NEW_WEB_API}/capitalcalls-api/Investors/GetCapitalCallsDates?IdVehicle=${idVehicle}&${query}`)
            .then((data) => { 
                if (data !== null) {
                    return resolve(data?.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    },
    GetDistributionDates(data: any):Promise<any> {
        let idVehicle =  data.idVehicle;
        let investors = data.investors;
        let query = investors.map((investor:any) => `IdInvestors=${investor}`).join('&');
        return new Promise((resolve, reject) => {
            AxiosAPI.get(`${process.env.REACT_APP_NEW_WEB_API}/distributions-api/Investors/GetDistributionsDates?IdVehicle=${idVehicle}&${query}`)
            .then((data) => { 
                if (data !== null) {
                    return resolve(data?.data);
                } else {
                    return reject(null);
                }
            }).catch((error) => {
                return reject(error);
            });
        });
    },
}

export default ReportsApi;