import React from "react";

function UnknownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <path d="M3.5 1v23h18V7.297l-.14-.156-6-6L15.202 1zm1 1h10v6h6v15h-16zm11 .719L19.781 7H15.5zm0 0"></path>
    </svg>
  );
}

export default UnknownIcon;
