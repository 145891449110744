import { Spin, Tooltip, Input, Row, Col } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import FiltersNavBar from './FiltersNavBar';

import PdfIcon from '../../assets/PdfIcon';
import ExcelIcon from '../../assets/ExcelIcon';
import WordIcon from '../../assets/WordIcon';
import UnknownIcon from '../../assets/Unknown';

import './SearchNavBar.scss';
import { ISearchNavBar } from './ISearchNavBar';

const { Search } = Input;

const SearchNavBar = (props: ISearchNavBar) => {
	const {
		showFilters,
		application,
		peips,
		filteredVehicles,
		funds,
		filters,
		handleFilterChange,
		onChangeFilters,
		isLoading,
		onChangeTags,
		openFile,
		isFileListOpen,
		openFileList,
		closeFileList,
		tagRender,
		handleSearch,
		valueSearch,
		hideOverflow,
		options,
		fetching,
		footerListText,
		tagsList
	} = props;

	const Icon = (extension: string) => {
		if (extension === 'pdf') {
			return <PdfIcon />;
		} else if (
			extension === 'xlsx' ||
			extension === 'xls' ||
			extension === 'csv'
		) {
			return <ExcelIcon />;
		} else if (extension === 'docx' || extension === 'doc') {
			return <WordIcon />;
		} else {
			return <UnknownIcon />;
		}
	};

	return (
		<>
			{isFileListOpen && (
				<div
					onClick={closeFileList}
					className="background-docs"
				/>
			)}

			{showFilters
				? <FiltersNavBar 
					onChangeFilters={onChangeFilters}
					filters={filters}
					isLoading={isLoading}
					filteredVehicles={filteredVehicles}
					peips={peips}
					funds={funds}
					setFilters={handleFilterChange}
					onChangeTags={onChangeTags}
					tagsList={tagsList}
					tagRender={tagRender}
					application={application}
				/>
				: null
			}

			<Row className={`search-nav-bar ${showFilters ? 'with-filters' : ''}`} justify="center" align="middle">
				{showFilters && (
					<Col className="search-input-wrapper">
						<Search
							placeholder="Search for file name"
							allowClear
							size="large"
							onChange={(e) => handleSearch(e.target.value)}
							value={valueSearch}
							style={{ width: 500, zIndex: 4 }}
							onFocus={openFileList}
						/>
					</Col>
				)}
				

				{isFileListOpen && (
					<Col
						className={`options-search-list ${hideOverflow && 'hideOverflow'} ${showFilters ? 'with-filters' : ''}`}
					>
						{options.slice(0, 9).map((x: any, i: number) => {
							return (
								<div className="options-search-row">
									<Tooltip
										title={`${x.folder}`}
										overlayClassName="options-search-tooltip"
										overlayStyle={{ width: '500px' }}
										key={i}
									>
										<button
											className="options-search"
											onClick={() => openFile(x)}
											>
											<div className="options-search-icon">
												{Icon(x.extension)}
											</div>
											<div>
												<div className="options-search-file">
													<a href={x.url}> {x.file}</a>
												</div>
												<div className="options-search-folder">
													{x.folder}
												</div>
											</div>
											<EyeOutlined className='view-doc-search'/>
										</button>
									</Tooltip>
								</div>
							);
						})}
						{(options.length >= 10 && options.length <= 20) && (
							<p className="search-criteria">
								More than{' '}
								<strong>{options.length}</strong>{' '}
								documents match the search criteria. 
							</p>
						)}
						{options.length > 20 && (
							<p className="search-criteria">
								More than{' '}
								<strong>{options.length}</strong>{' '}
								documents match the search criteria. <br/>
								Please refine your search for fewer results or consider <strong> using the filters</strong>.
							</p>
						)}
						{options.length === 0 && (
							<p className="enter-text-p">
								{fetching ? (
									<Spin size="small" />
								) : (
									footerListText
								)}
							</p>
						)}
					</Col>
				)}
			</Row>
		</>
	);
};

export default SearchNavBar;
