import React from "react";

function PdfIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g>
        <path
          fill="#A3313A"
          d="M5.078 21.875l-1.957-1.953h1.957zm14.844 0l1.957-1.953h-1.957zm0 0"
        ></path>
        <path fill="#B2B2B2" d="M19.922 5.078H16.8V1.957zm0 0"></path>
        <path
          fill="#F1F1F1"
          d="M16.797 1.953H5.699a.62.62 0 00-.62.621v19.922c0 .344.276.621.62.625h13.602c.168 0 .328-.07.445-.191a.63.63 0 00.176-.453V5.078h-3.125zm0 0"
        ></path>
        <path
          fill="#C84D52"
          d="M3.516 12.89h17.968c.215 0 .391.176.391.391v6.64H3.125v-6.64c0-.215.176-.39.39-.39zm0 0"
        ></path>
        <path
          fill="#FFF"
          d="M9.246 16.848v1.12h-.781v-3.195h1.25a1.21 1.21 0 01.867.286.99.99 0 01.3.78c.005.184-.042.368-.132.528a.933.933 0 01-.39.367c-.2.094-.419.141-.637.133zm.86-1.028c0-.28-.157-.422-.47-.422h-.39v.832h.39c.313 0 .47-.136.47-.41zm3.835 1.367a1.416 1.416 0 01-.578.567c-.273.14-.578.21-.886.203H11.27v-3.184h1.207c.308-.007.613.059.886.2.246.125.446.32.578.562.137.254.207.543.2.832a1.72 1.72 0 01-.2.82zm-.832-.16c.336-.386.336-.96 0-1.347a.984.984 0 00-.703-.239h-.363v1.825h.363a.98.98 0 00.703-.227zm3.555-2.254v.618h-1.312v.699h1.011v.586h-1.011v1.293h-.782v-3.196zm0 0"
        ></path>
        <path
          fill="none"
          stroke="#C84D52"
          strokeMiterlimit="10"
          strokeWidth="0.391"
          d="M12.852 4.113c.027.27.003.543-.079.805-.347 1.2-1.511 5.57-2.39 6.191-1.254.891-1.906-.12-1.238-.84.668-.718 4.699-1.675 5.984-1.675 1.672 0 1.457 1.48.508 1.39-1.07-.101-3.746-1.925-3.907-5.394-.07-1.235 1-1.465 1.122-.477zm0 0"
        ></path>
      </g>
    </svg>
  );
}

export default PdfIcon;