import { Layout } from "antd";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import FeedbackModal, { useFeedbackModal } from '@qualitas/feedback-modal';
import { AnalysisAndReportingAPI } from '@qualitas/reporting';
import FilesViewer, { FilesViewerAPI, useFilesViewer } from '@qualitas/files-viewer';
import { PrivateRoute } from '@qualitas/auth';

import Documents from "./Components/Documents/Documents";

import { IPrivateContentLayout } from "./IPrivateContentLayout";
import AppSelectorDocManagment from "./Components/AppSelectorDocManagment/AppSelectorDocManagment";
import { useEffect, useState } from "react";
import FiltersAPI from "./api/FiltersAPI";
import useSearchNavBar from "./hooks/useSearchNavBar";
import MenuNavbar from "./Components/MenuNavbar/MenuNavbar";
import { Footer } from '@qualitas/navbar';
import HomePage from "./Components/MenuNavbar/HomePage";
import { NavbarApi, useNavbar } from '@qualitas/navbar';
import { languages } from "./assets/languages";
import PortfolioDetailsReporting from "./Components/Reporting/PortfolioDetailsReporting";
import CashflowReporting from "./Components/Reporting/CashflowReporting";
import useApp from "./hooks/useApp";
import GenerateDocumentsContainer from "./Components/Documents/GenerateDocuments/GenerateDocumentsContainer";

const PrivateContentLayout = ({
	baseUrl,
	getAuthToken,
	userIdInstitution,
	userName,
	userContactId,
	userLogo,
	logOut,
	authProps, 
	navigate,
	language, 
	setLanguage,
	validateAuthToken,
	userPrimaryColor
}: IPrivateContentLayout) => {
	const [application, setApplication] = useState<string>();
	const [investorsFilterPowerBI, setInvetorsFilterPowerBI] = useState<string[]>();
	const [translations, setTranslations]: any = useState([]);
	let location = useLocation();

	useEffect(() => {
		setApplication(getApplication());
	}, [location.pathname]);
	
	const getApplication = () => {
		const app = location.pathname.split('/').reverse()[0];
		switch (app) {
			case 'InvestmentDocuments':
				return 'Investment Documents';
			case 'InvestorPortal':
				return 'Investor Portal';
			case 'Payments':
				return 'Payments';
			case 'DataRoom':
				return 'Data Room';
		};
	};

	const feedbackModalProps = useFeedbackModal({});

	const filtersAPI = FiltersAPI({ 
		showServiceFeedbackModal: feedbackModalProps.showServiceFeedbackModal
	});

	useEffect(() => {
		if (userContactId)
			filtersAPI.getInvestorsFilterPowerBI(userContactId)
				.then((investors: string[]) => setInvetorsFilterPowerBI(investors))
	}, [userContactId]);

	useEffect(() => {
		switch (language) {
			case 'es': setTranslations(languages.es);
				break;
			case 'en': setTranslations(languages.en);
				break;
		}
	}, [language]);

	const {
		fetching,
		options,
		valueSearch,
		isFileListOpen,
		hideOverflow,
		footerListText,
		peips,
		filteredVehicles,
		funds,
		filters,
		tagsList,
		selectedTags,
		tagRender,
		openFileList,
		closeFileList,
		handleSearch,
		handleFilterChange,
		onChangeFilters,
		onChangeTags
	} = useSearchNavBar({ api: filtersAPI, application });

	const api = AnalysisAndReportingAPI({
		baseUrl,
		getAuthToken: getAuthToken,
		showServiceFeedbackModal: feedbackModalProps.showServiceFeedbackModal
	});
	
	const { menuData, mainColor, inLineStyle
	 } = useApp({ idInstitution: userIdInstitution, userPrimaryColor});

	const filesViewerAPI = FilesViewerAPI({
		baseUrl,
		getAuthToken,
		showServiceFeedbackModal: feedbackModalProps.showServiceFeedbackModal
	});
	const fileViewerModalProps = useFilesViewer({
		api: filesViewerAPI,
		appName: application,
		idPeip: filters.Peip,
		idVehicle: filters.Vehicle,
		idFund: filters.Fund,
		idLanguage: filters.Language !== 0 ? filters.Language : undefined,
		tags: selectedTags.length > 0 ? selectedTags : null,
		startDate: filters.StartDate,
		endDate: filters.EndDate
	});

	let migratedUrl = process.env.REACT_APP_InvestorPortal
	let notMigratedUrl = process.env.REACT_APP_ReportingUrl

	const idSystemMaster = 7;
	const apiNavbar = NavbarApi(baseUrl, getAuthToken);
	const { menuItems, ...NavbarProps } = useNavbar({
		api: apiNavbar,
		idSystemMaster,
		userData: {...authProps},
		migratedUrl,
		notMigratedUrl,
		navigate,
		sendResetPasswordEmail: () => authProps.sendResetPasswordEmail(),
		language
	});

	return (
		<Layout className="layout" style={ inLineStyle }>
			{location.pathname !== '/' &&
			 <MenuNavbar 
				baseUrl={baseUrl} 
				logout={logOut}
				language={language}
				setLanguage={setLanguage}
				menuItems={menuItems}
				getAuthToken={getAuthToken} 
				authProps={authProps}
				navbarProps={NavbarProps}
				sendResetPasswordEmail={authProps.sendResetPasswordEmail}
			/>
		}
			<Routes>
				<Route element={<PrivateRoute validateAuthToken={validateAuthToken}/>}>
					<Route path="/" element={
						<HomePage 
							menuItems={menuItems} 
							logout={logOut} 
							language={language} 
							setLanguage={setLanguage} 
							translations={translations}
							menuData={menuData}
							mainColor={mainColor}
							userLogo={userLogo}
							userPrimaryColor={userPrimaryColor}
							/>
					}/>
					<Route path="PortfolioDetail/Dynamic" element={
						<PortfolioDetailsReporting 
							api={api}
							userIdInstitution={userIdInstitution}
						/>
					}/>
					<Route path="/Cashflows" element={
						<CashflowReporting
							api={api}
							userIdInstitution={userIdInstitution}
							investorsFilterPowerBI={investorsFilterPowerBI}
						/>
					}/>
					<Route path="/Documents/View" element={
						<AppSelectorDocManagment
							setApplication
							application={application}
							peips={peips}
							filteredVehicles={filteredVehicles}
							funds={funds}
							filters={filters}
							handleFilterChange={handleFilterChange}
							onChangeFilters={onChangeFilters}
							onChangeTags={onChangeTags}
							tagsList={tagsList}
							onSelectSearchFile={fileViewerModalProps.onSelectFile}
							openSearchPDFViewerModal={fileViewerModalProps.openPDFViewerModal}
							isFileListOpen={isFileListOpen}
							openFileList={openFileList}
							closeFileList={closeFileList}
							tagRender={tagRender}
							handleSearch={handleSearch}
							valueSearch={valueSearch}
							hideOverflow={hideOverflow}
							options={options}
							fetching={fetching}
							footerListText={footerListText}
							openFileByUrlAndApp={fileViewerModalProps.openFileByUrlAndApp}
						/>
					}/>
					<Route path="Documents/Generate" element={
						<GenerateDocumentsContainer
							translations={translations}
							mainColor={mainColor}
							language={language}
						/>
					}/>
					<Route path="/Documents/View/*" element={
						<>
							{application
								? <Documents
									baseUrl={baseUrl}
									getAuthToken={getAuthToken}
									showServiceFeedbackModal={feedbackModalProps.showServiceFeedbackModal}
									userName={userName}
									userContactId={userContactId}
									userIdInstitution={userIdInstitution}
									application={application}
									filters={filters}
									peips={peips}
									filteredVehicles={filteredVehicles}
									funds={funds}
									handleFilterChange={handleFilterChange}
									onChangeFilters={onChangeFilters}
									onChangeTags={onChangeTags}
									tagsList={tagsList}
									selectedTags={selectedTags}
									onSelectSearchFile={fileViewerModalProps.onSelectFile}
									openSearchPDFViewerModal={fileViewerModalProps.openPDFViewerModal}
									isFileListOpen={isFileListOpen}
									openFileList={openFileList}
									closeFileList={closeFileList}
									tagRender={tagRender}
									handleSearch={handleSearch}
									valueSearch={valueSearch}
									hideOverflow={hideOverflow}
									options={options}
									fetching={fetching}
									footerListText={footerListText}
									openFileByUrl={fileViewerModalProps.openFileByUrl}
									translations={translations}
								/>
								: null 
							}
						</>
					}/>
					<Route path="*" element={<Navigate to="/"/>}/>
				</Route>
			</Routes>
			<FilesViewer
				documentToken={fileViewerModalProps.documentToken}
				isPDFViewerOpen={fileViewerModalProps.isPDFViewerOpen}
				disableRightClick={fileViewerModalProps.disableRightClick}
				closePDFViewerModal={fileViewerModalProps.closePDFViewerModal}
			/>
			<Footer language={language} />
			<FeedbackModal {...feedbackModalProps}/>
		</Layout>
	);
};

export default PrivateContentLayout;