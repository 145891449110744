import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SectionBlock.scss";
const SectionBlock = (props: any) => {
  const { options, title, icon } = props;
  return (
    <div className="section-block">
      <div className="section-icon">
        <div className="section-icon-circle">
          <FontAwesomeIcon icon={icon} />
        </div>
        <p>{title}</p>
      </div>
      <div className="section-options-container">
        {options.map((option: any, index: number) => {
          return (
            <div key={index}>{option}</div>
          );
        })}
      </div>
    </div>
  );
};

export default SectionBlock;
