import { useEffect } from "react";
import GenerateDocuments from "./GenerateDocuments";
import useDocuments from "../../../hooks/useDocuments";
import DocumentsSidebar from "./DocumentsSidebar";
import './Documents.scss';

const GenerateDocumentsContainer = (props: any) => {
  const { mainColor, translations, language } = props;

  const {
    peipList,
    selectedPeip,
    handleChangePeip,
    documentsBy,
    handleChangeDetailsBy,
    shouldShowFileViewer,
    setShouldShowFileViewer,
    isSidebarLoading,
    selectedInvestors,
    investorsList,
    handleInvestorsCheckbox,
    selectedPeipDocuments,
    isTableLoading,
    setDocumentsBy,
  } = useDocuments();

  useEffect(() => {
    setShouldShowFileViewer(false);
    if (selectedPeipDocuments && language) {
      setShouldShowFileViewer(true);
    }
  }, [selectedPeipDocuments, language]);

  return (
    <div className="layout-documents">
      <DocumentsSidebar
        selectedPeip={selectedPeip}
        handleChangePeip={handleChangePeip}
        translations={translations}
        peipList={peipList}
        documentsBy={documentsBy}
        loading={isSidebarLoading}
        handleChangeDetailsBy={handleChangeDetailsBy}
        selectedPeipDocuments={selectedPeipDocuments}
      />

      <GenerateDocuments
        translations={translations}
        language={language}
        mainColor={mainColor}
        investorsList={investorsList}
        handleInvestorsCheckbox={handleInvestorsCheckbox}
        selectedInvestors={selectedInvestors}
        isTableLoading={isTableLoading}
      />
    </div>
  );
};

export default GenerateDocumentsContainer;
