import React from 'react';

function ExcelIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="25"
			height="25"
			viewBox="0 0 25 25"
		>
			<g>
				<path
					fill="#169154"
					d="M15.105 3.125H8.2c-.5 0-.906.406-.906.91v3.777h7.812zm0 0"
				></path>
				<path
					fill="#18482A"
					d="M7.293 17.215v3.75c0 .504.406.91.906.91h6.906v-4.66zm0 0"
				></path>
				<path
					fill="#0C8045"
					d="M7.293 7.813h7.812v4.69H7.293zm0 0"
				></path>
				<path
					fill="#17472A"
					d="M7.293 12.504h7.812v4.71H7.293zm0 0"
				></path>
				<path
					fill="#29C27F"
					d="M22.008 3.125h-6.903v4.688h7.813V4.034a.909.909 0 00-.91-.91zm0 0"
				></path>
				<path
					fill="#27663F"
					d="M15.105 17.215v4.66h6.903c.504 0 .91-.406.91-.906v-3.754zm0 0"
				></path>
				<path
					fill="#19AC65"
					d="M15.105 7.813h7.813v4.69h-7.813zm0 0"
				></path>
				<path
					fill="#129652"
					d="M15.105 12.504h7.813v4.71h-7.813zm0 0"
				></path>
				<path
					fill="#0C7238"
					d="M11.625 17.707H2.957a.876.876 0 01-.875-.875V8.168c0-.484.395-.875.875-.875h8.668c.484 0 .875.39.875.875v8.664c0 .484-.39.875-.875.875zm0 0"
				></path>
				<path
					fill="#FFF"
					d="M5.11 9.895h1.242l1.007 1.957 1.067-1.957h1.16L7.984 12.5l1.637 2.605H8.398L7.297 13.06l-1.094 2.046H4.961l1.664-2.613zm0 0"
				></path>
			</g>
		</svg>
	);
}

export default ExcelIcon;
