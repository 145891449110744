import  { useEffect, useState } from 'react'
import { InvestorPortalApi } from '../api/DocumentsAPI';
import { PeipInvestor, SelectedInvestor, VehicleInvestor } from '../Models/DocumentsModels';

const useDocuments = () => {
    const [selectedPeipDocuments, setSelectedPeipDocuments] = useState<number>(0);
    const [selectedPeip, setSelectedPeip] = useState<number>(0);
    const [peipList, setPeipList] = useState<PeipInvestor[]>([]);
    const [investorsList, setInvestorsList] = useState<VehicleInvestor[]>([]);
    const [documentsBy, setDocumentsBy] = useState<string>('investors');
    const [isSidebarLoading, setIsSidebarLoading] = useState<boolean>(false);
    const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
    const [shouldShowFileViewer, setShouldShowFileViewer] = useState<boolean>(false);
    const [selectedInvestors, setSelectedInvestors] = useState<SelectedInvestor>({idVehicle: 0, investors: []});
    
    useEffect(() => {
        if(peipList.length === 0){
            getMenuItems()
        }
        if(documentsBy === 'investors' && selectedPeip !== 0){
            getInvestorsList(selectedPeip)
        }
    }, [documentsBy, selectedPeip])
    
    
    const handleChangePeip = (id: number, documentsBy: string) => {
      if(documentsBy === 'investors'){
        setSelectedInvestors({idVehicle: 0, investors: []});
        setSelectedPeip(id)
      } else {
        setSelectedPeipDocuments(id)
      }
    }
    const handleChangeDetailsBy = (option: string) => {
        setDocumentsBy(option);
    }

    const getMenuItems = async() => {
        setIsSidebarLoading(true);
        await InvestorPortalApi.getMenuItems().then((response: any) => {
          if(response.success){
            const peipsData = response.data.filter((item: any) => item.type === 'funds');
            setPeipList(peipsData[0]?.items || []);
            if (peipsData.length > 0 && peipsData[0]?.items[0]?.funds[0]) {
              const { idFund } = peipsData[0].items[0].funds[0];
              setSelectedPeip(idFund);
              setSelectedPeipDocuments(idFund);
            }
          }
        })
        .finally(() => setIsSidebarLoading(false))
      }
  
    const handleInvestorsCheckbox = (checked: boolean, idVehicle: number, idInvestor: number) => {
      setSelectedInvestors((prevSelectedInvestors: SelectedInvestor) => {
        if(!checked){
          return { idVehicle, investors: prevSelectedInvestors.investors.filter((item: number) => item !== idInvestor) };
        } 
        let existingVehicle = prevSelectedInvestors.idVehicle === idVehicle;
        if (existingVehicle) {    
          return { idVehicle, investors: [...prevSelectedInvestors.investors, idInvestor ] };
        } else {
          return { idVehicle, investors: [idInvestor] }
        }
      });
    };

    const getInvestorsList = async(idInvestor: number) => {
      setIsTableLoading(true);
      await InvestorPortalApi.getInvestorList(idInvestor).then((response: any) => {
        if(response.success){
          setInvestorsList(response.data)
          console.log(response.data);
        }
      })
      .finally(() => setIsTableLoading(false))
    }
    
  return{
    selectedPeip,
    handleChangePeip,
    peipList,
    setPeipList,
    documentsBy,
    handleChangeDetailsBy,
    shouldShowFileViewer, 
    setShouldShowFileViewer,
    isSidebarLoading,
    selectedInvestors,
    investorsList,
    handleInvestorsCheckbox,
    selectedPeipDocuments,
    isTableLoading,
    setDocumentsBy
  }
}

export default useDocuments