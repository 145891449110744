import axios from "axios";

let apiConfig = {
	getAuthToken: () => ''
};

const initAPIs = (getAuthToken: () => string) => {
	apiConfig.getAuthToken = getAuthToken;
};

const AxiosAPI = axios.create({
	baseURL: process.env.REACT_APP_NEW_WEB_API,
	headers: {
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	}
});

AxiosAPI.interceptors.request.use(function (config) {
	if(config?.headers){
		config.headers['Authorization'] = `Bearer ${apiConfig.getAuthToken()}`;
	}
	return config;
});

AxiosAPI.interceptors.response.use(undefined, function (error) {
		if (error.response.status === 401) {
		window.location.href = '/login';
		}
});

export { AxiosAPI, initAPIs };