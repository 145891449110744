const languages = {
  es: {
    idioma: "Idioma",
    inversores: "Inversores",
    documentos: "Documentos",
    analiza: "Analiza tu inversión",
    cargando: "Cargando Datos...",
    buscar: "Buscar inversor",
    busquedaHolder: "Escriba el nombre del inversor",
    descargarExcel: "Descargar Excel",
    grupo: "Grupo",
    compromiso: "Compromiso",
    compromisoGrafico: "Comp.",
    contribuciones: "Contribuciones",
    distribuciones: "Distribuciones",
    participaciones: "Participaciones",
    tuInversion: "Resumen",
    documentosInversores: "Documentos de inversores",
    nombreDocumento: "Nombre del Documento",
    fondos: "Fondos",
    anuncios: "Anuncios",
    portalInversor: "Portal del inversor",
    contacto: "Contacto",
    accesoDenegado: "Acceso denegado",
    accesoDenegadoTexto: "El usuario actual no tiene acceso a esta aplicación",
    SeleccionaDocumento: "Selecciona un documento",
    ampliar: "Ampliar",
    seleccionarTodos: "Seleccionar Todos",
    diaInversor: "Día del Inversor",
    presentacion: "Presentación",
    noMostrarMas: "No volver a mostrar",
    planifica: "Planifica tu inversión",
    videos: "Vídeos",
    dataRoom: "Data Room",
    enlarge: "Pantalla completa",
    download: "Descargar",
    expertsIn: "Expertos en",
    //Menu
    ControlReports: "Reportes de control",
    PortfolioDetail: "Detalle de la cartera",
    Cashflows: "Flujos de caja",
    View: "Ver documentos",
    Generate: "Generar documentos",
    Documents: "Documentos",
    privacyPolicy: "Política de privacidad",
    cookiesPolicy: "Política de cookies",
    disclaimer: "Aviso legal",
    allRightsReserved: "Todos los derechos reservados",
    static: "Reportes estáticos",

    documentosDelFondo: "Documentos del fondo",
    generarDocumento: "Generar documentos",
    fof: "Fondo de Fondos",
    coinvestFunds: "Fondos de coinversión",
    generar: "Generar",
    certificadoDeParticipacion: "Certificado de participación",
    certificadoParticipacion: "Certificado participación",
    solicitudDesembolso: "Solicitud de desembolso",
    cartaCompromiso: "Carta de compromiso",
    notificacionDistribucion: "Notificación de distribución",
    enFecha: "En fecha",
    generarDocumentos: "Generar documentos",
    seleccioneFormulario: "Seleccione los documentos que desea generar",
    seleccioneMismoVehiculo: "Seleccione invesores del mismo vehículo",
    docs: "Docs",
	fechasCombinadas: "Si se seleccionan múltiples inversores, las fechas en el listado corresponden a todos ellos. Tenga en cuenta que al generar el documento, algunos inversores podrían no figurar si no tienen movimientos en esa fecha específica.",
  },
  en: {
    idioma: "Language",
    inversores: "Investors",
    documentos: "Documents",
    analiza: "Analyze Your Investment",
    cargando: "Loading Data...",
    buscar: "Search investor",
    busquedaHolder: "investor name",
    descargarExcel: "Download Excel",
    grupo: "Group",
    compromiso: "Commitment",
    compromisoGrafico: "Comm.",
    contribuciones: "Amount",
    distribuciones: "Capital Gain",
    participaciones: "Shares",
    tuInversion: "Resume",
    documentosInversores: "Investor documents",
    nombreDocumento: "Document name",
    fondos: "Funds",
    anuncios: "Announcements",
    portalInversor: "Investor portal",
    contacto: "Contact",
    accesoDenegado: "Access denied",
    accesoDenegadoTexto:
      "The current user does not have access to this application",
    SeleccionaDocumento: "Select a Document",
    ampliar: "Open",
    seleccionarTodos: "Select All",
    diaInversor: "Investor Day",
    presentacion: "Presentation",
    noMostrarMas: "Don't show again",
    planifica: "Plan your investment",
    videos: "Videos",
    dataRoom: "Data Room",
    enlarge: "Full screen",
    download: "Download",
    expertsIn: "Experts in",
    //Menu
    ControlReports: "Control Reports",
    PortfolioDetail: "Portfolio Detail",
    Cashflows: "Cashflows",
    View: "View documents",
    Generate: "Generate documents",
    Documents: "Documents",
    privacyPolicy: "Privacy Policy",
    cookiesPolicy: "Cookies Policy",
    disclaimer: "Disclaimer",
    allRightsReserved: "All rights reserved",
    static: "Static reports",

    documentosDelFondo: "Fund documents",
    generarDocumento: "Generate documents",
    fof: "Fund of Funds",
    coinvestFunds: "Co-investment funds",
    generar: "Generate",
    certificadoDeParticipacion: "Capital Account",
    certificadoParticipacion: "Capital Account",
    solicitudDesembolso: "Capital Call Notice",
    cartaCompromiso: "Commitment Agreement",
    notificacionDistribucion: "Distribution Notice",
    enFecha: "On date",
    generarDocumentos: "Generate documents",
    seleccioneFormulario: "Select the documents you want to generate",
    seleccioneMismoVehiculo: "Select investors of the same vehicle",
    docs: "Docs",
	fechasCombinadas: "If multiple investors are selected, the dates in the list correspond to all of them. Please note that when generating the document, some investors may not appear if they have no transactions on that specific date.",
  },
};
export { languages };
