import { useNavigate } from 'react-router-dom';
import AppCard from './AppCard';
import {
	BarChartOutlined,
	UserOutlined,
	DatabaseOutlined,
} from '@ant-design/icons';
import './AppSelector.scss';

const AppSelector = () => {
	
	const navigate = useNavigate();
	
	const goTo = (application: string) => {
		switch (application) {
			case 'Investment Documents':
				navigate(`/Documents/View/InvestmentDocuments`)
				break;
			case 'Data Room':
				navigate(`/Documents/View/DataRoom`)
				break;
			case 'Payments':
				navigate(`/Documents/View/Payments`)
				break;
			case 'Investor Portal':
				navigate(`/Documents/View/InvestorPortal`)
				break;
			default: navigate(`/Documents/View/InvestmentDocuments`)
				break;
		}
	};

	return (
		<div className="app-selector">
			<p className="app-selector-title"> Welcome to the <strong>Document Manager</strong></p>
			<p className="app-selector-subtitle"> Please pick an <strong>App</strong> to access</p>
			
			<div className="app-selector-cards">
				<AppCard
					icon={<BarChartOutlined />}
					color={'blue'}
					firstWord={'Investment'}
					secondWord={'Documents'}
					description="Documents related to Investment, as Capital Calls, Distributions and Closings."
					onClick={() =>
						goTo('Investment Documents')
					}
				/>
				<AppCard
					icon={<UserOutlined />}
					color={'orange'}
					firstWord={'Investor'}
					secondWord={'Reports'}
					app={'Investor Portal'}
					description="Documents related to Investors"
					onClick={() =>
						goTo('Investor Portal')
					}
				/>
				<AppCard
					icon={<DatabaseOutlined />}
					color={'red'}
					firstWord={'Data Room'}
					secondWord={'New Products'}
					description="Documents related to Data Room"
					onClick={() =>
						goTo('Data Room')
					}
				/>
			</div>
		</div>
	);
};

export default AppSelector;
