import { AxiosResponse } from 'axios';

import { AxiosAPI } from "./api";

const HomeAPI = () => {
	const getPanels = (showAll: boolean, idInstitution: number | undefined, idPanel: number | undefined) => {
		const serviceUrl = `/reporting-api/AnalysisAndReporting/GetAnalysisAndReporting`;
		return AxiosAPI.get(serviceUrl, {
            params: {
                showAll,
                idInstitution,
                idPanel
            }
        })
            .then((response: AxiosResponse) => response.data)
            .catch((error: any) => {
                console.log(error.response.status, `${serviceUrl}`);
            })
	};

    return {
        getPanels,
    };
};

export default HomeAPI;