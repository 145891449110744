import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Button, Spin } from 'antd';

import { CaretLeftOutlined } from '@ant-design/icons';

import FilesViewer, { FilesViewerAPI, useFilesViewer } from '@qualitas/files-viewer';

import SearchNavBar from '../SearchNavBar/SearchNavBar';

import '../../../node_modules/@qualitas/files-viewer/dist/FilesViewer.scss';
import './Documents.scss';

const Documents = ({
	baseUrl,
	getAuthToken,
	showServiceFeedbackModal,

	application,
	filters,
	isLoading,
	peips,
	filteredVehicles,
	funds,
	handleFilterChange,
	onChangeFilters,
	onChangeTags,
	selectedTags,
	tagsList,
	onSelectSearchFile,
	openSearchPDFViewerModal,
	isFileListOpen,
	openFileList,
	closeFileList,
	tagRender,
	handleSearch,
	valueSearch,
	hideOverflow,
	options,
	fetching,
	footerListText,
	openFileByUrl,
	translations
}: any) => {
	const navigate = useNavigate();
	const [shouldShowFileViewer, setShouldShowFileViewer] = useState<boolean>(false);

	useEffect(() => {
		setShouldShowFileViewer(false);
		setShouldShowFileViewer(true);
	}, [filters.Peip, filters.Vehicle, filters.Fund, filters.Language])

	const filesViewerAPI = FilesViewerAPI({
		baseUrl,
		getAuthToken,
		showServiceFeedbackModal
	});

	const fileViewerProps = useFilesViewer({
		api: filesViewerAPI,
		appName: application,
		idPeip: filters.Peip,
		idVehicle: filters.Vehicle,
		idFund: filters.Fund,
		idLanguage: filters.Language !== 0 ? filters.Language : undefined,
		tags: selectedTags.length > 0 ? selectedTags : null,
		startDate: filters.StartDate,
		endDate: filters.EndDate
	});

	return (
		<Routes>
			<Route path="*" element={
					<div className="documents-viewer">
						<SearchNavBar
							showFilters
							application={application}
							peips={peips}
							filteredVehicles={filteredVehicles}
							funds={funds}
							filters={filters}
							handleFilterChange={handleFilterChange}
							onChangeFilters={onChangeFilters}
							isLoading={isLoading}
							tagsList={tagsList}
							onChangeTags={onChangeTags}
							openFile={(x: any) => {
								openFileByUrl(x.blobStorageURL);
							}}
							isFileListOpen={isFileListOpen}
							openFileList={openFileList}
							closeFileList={closeFileList}
							tagRender={tagRender}
							handleSearch={handleSearch}
							valueSearch={valueSearch}
							hideOverflow={hideOverflow}
							options={options}
							fetching={fetching}
							footerListText={footerListText}
						/>
						{shouldShowFileViewer
						 	? <FilesViewer
							 isLoading={fileViewerProps.isLoading}
							 isLoadingDocument={fileViewerProps.isLoadingDocument}
							 texts={{...translations, title: ''}}
							 documentToken={fileViewerProps.documentToken}
							 isPDFViewerOpen={fileViewerProps.isPDFViewerOpen}
							 folderTree={fileViewerProps.folderTree}
							 openPDFViewerModal={fileViewerProps.openPDFViewerModal}
							 disableRightClick={fileViewerProps.disableRightClick}
							 onSelectFile={fileViewerProps.onSelectFile}
							 closePDFViewerModal={fileViewerProps.closePDFViewerModal}
							 showMenu
							 shouldExpandAll={fileViewerProps.shouldExpandAll}
							 onExpandFolder={fileViewerProps.onExpandFolder}
							 expandedKeys={fileViewerProps.expandedKeys}
							 hideLoadingDocument={fileViewerProps.hideLoadingDocument}
										   />
							: null
						}
					</div>
			}/>
		</Routes>
	);
}

export default Documents;
