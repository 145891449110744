import { Button, Col, DatePicker, Row, Select } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import Filter from '../Filters/Filter';

const { RangePicker } = DatePicker;

const FiltersNavBar = (props: any) => {
	const {
		onChangeFilters,
		filters,
		isLoading,
		filteredVehicles,
		peips,
		funds,
		setFilters,
		onChangeTags,
		tagsList,
		tagRender
	} = props;

	return (
		<Row className="document-management-filters" justify="center" align="bottom">
			<Col>
				<Row justify="space-between">
					<label>{`Select Tags:`}</label>
					<div className="selector-col">
						<Select
							className="selector"
							placeholder={`Search by Tags:`}
							mode="tags"
							allowClear	
							tagRender={tagRender}
							filterOption={(input, option) =>
								(option?.label ?? '')
									.toString()
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							onChange={(value: any) => onChangeTags(value)}
							options={tagsList}
						/>
					</div>
				</Row>
			</Col>
			<Col>
				<Row justify="space-between">
					<label>Select Range:</label>
					<div className="selector-col">
						<RangePicker 
							disabled={isLoading}
							onChange={(e: any) => onChangeFilters(e, 'dates')}
						/>
					</div>
				</Row>
			</Col>			
			<Filter
				onChangeFilters={onChangeFilters}
				label={'Language'}
				value={filters.Language}
				options={[
					{ label: 'All', value: 0 },
					{ label: 'English', value: 1 },
					{ label: 'Spanish', value: 2 },
				]}
				isLoading={isLoading}
			/>
			<Filter
				onChangeFilters={onChangeFilters}
				label={'Peip'}
				value={filters.Peip}
				options={peips}
				isLoading={isLoading}
			/>
			<Filter
				onChangeFilters={onChangeFilters}
				label={'Vehicle'}
				value={filters.Vehicle}
				options={filteredVehicles}
				isLoading={isLoading}
				disabled={!filters.Peip}
			/>
			<Filter
				onChangeFilters={onChangeFilters}
				label={'Fund'}
				value={filters.Fund}
				options={funds}
				isLoading={isLoading}
				disabled={!filters.Vehicle}
			/>
			<Button
				onClick={() => setFilters({ Language: 2 })}
				type="text"
			>
				<ReloadOutlined /> <p>Clear</p>
			</Button>
		</Row>
	);
};

export default FiltersNavBar;
