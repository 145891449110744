import AppSelector from '../AppSelector/AppSelector';
import SearchNavBar from '../SearchNavBar/SearchNavBar';

const AppSelectorDocManagment = ({
	application,
	peips,
	filteredVehicles,
	funds,
	filters,
	handleFilterChange,
	onChangeFilters,
	onChangeTags,
	tagsList,
	isLoading,
	onSelectSearchFile,
	openSearchPDFViewerModal,
	isFileListOpen,
	openFileList,
	closeFileList,
	tagRender,
	handleSearch,
	valueSearch,
	hideOverflow,
	options,
	fetching,
	footerListText,
	openFileByUrlAndApp
}: any) => (
	<div >
		<SearchNavBar
			application={application}
			peips={peips}
			filteredVehicles={filteredVehicles}
			funds={funds}
			filters={filters}
			handleFilterChange={handleFilterChange}
			onChangeFilters={onChangeFilters}
			isLoading={isLoading}
			tagsList={tagsList}
			onChangeTags={onChangeTags}
			openFile={(x: any) => {
				openFileByUrlAndApp(x.blobStorageURL, x.application);
			}}
			isFileListOpen={isFileListOpen}
			openFileList={openFileList}
			closeFileList={closeFileList}
			tagRender={tagRender}
			handleSearch={handleSearch}
			valueSearch={valueSearch}
			hideOverflow={hideOverflow}
			options={options}
			fetching={fetching}
			footerListText={footerListText}
		/>
		<AppSelector />
	</div>
);

export default AppSelectorDocManagment