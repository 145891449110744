import {
  faFolder,
  faMoneyBill1,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionBlock from "../SectionBlock/SectionBlock";
import { faAngleRight, faAnglesRight, faChartLine } from "@fortawesome/free-solid-svg-icons";
import HomePageMenu from "./HomePageMenu";
import { useNavigate } from "react-router-dom";

const HomePage = ({ menuItems, logout, translations, language, setLanguage, menuData, userLogo, userPrimaryColor }: any) => {
  const navigate = useNavigate();

  let icons = {
    "Control Reports": faChartLine,
    "Cashflows": faMoneyBill1,
    "Documents": faFolder,
  };

  let reportingUrl = process.env.REACT_APP_ReportingUrl;

  const mapSectionBlock = (item: any, i: number) => (
    <SectionBlock
      key={`${item.url}_${i}`}
      options={item.items.map((subItem: any, index: number) => {
        if(item.menu && item.menu.includes('Control Reports')){
          if(subItem.url === '/PortfolioDetail/Dynamic'){
            return menuData.map((item: any) =>{
              let report = `${subItem.url}`
              if(item.sections.length > 0){
                report = `${subItem.url}?report=${item.sections[0].id}`
              }
              return (
                <button
                    key={index}
                    className="section-btn"
                    onClick={() => navigate(report)}
                    >
                  <p>{item.panelName}</p>
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              )
            }
            )
          }else if(subItem.label === 'Static Reports'){
            return (
              <button
                key={index}
                className="section-btn"
                onClick={() => window.location.href = `${reportingUrl}`}
                >
              <p>{subItem.label}</p>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
            )
          }
        }
        else if(item.url === '/Cashflows'){
          return(
            <button
              key={index}
              className="section-btn"
              onClick={() => navigate(subItem.url)}
              >
              <p>{translations[subItem.url.replace("/", "")]}</p>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          )
        }
        else if(subItem.url && subItem.url.includes('/Documents'))
        {
        return(
            <button
              key={index}
              className="section-btn"
              onClick={() => navigate(subItem.url)}
              >
              <p>{translations[subItem.url.replaceAll("/Documents/", '').replace("/", "")]}</p>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          )
        }
    })}
      icon={icons[item.menu as keyof typeof icons]}
      title={translations[item.menu.replaceAll(" ", "")]}
    />
  );

  return (
    <div className="qf-homepage">
      <HomePageMenu logout={logout} language={language} setLanguage={setLanguage} userLogo={userLogo}/>
      <div className="support-content">
        <div className="title">
          <p>
            {translations.expertsIn} <br />
            <span>
              <FontAwesomeIcon icon={faAnglesRight} style={{color: userPrimaryColor}}/>
            </span>
            <strong> lower mid-market</strong>
          </p>
        </div>
        <div className="sections-grid">
          {menuItems.map((item: any, index: number) => {
            if (
              item.menu === "Control Reports" ||
              item.url === "/Cashflows" ||
              (!item.url && item.menu === "Documents")
            ) {
              return mapSectionBlock(item, index);
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
