import React from "react";

function WordIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
    >
      <g>
        <path
          fill="#2D92D4"
          d="M22.008 3.125H8.199c-.5 0-.906.406-.906.91v3.777h15.625V4.035a.909.909 0 00-.91-.91zm0 0"
        ></path>
        <path
          fill="#2150A9"
          d="M7.293 17.215v3.75c0 .504.406.91.906.91h13.809c.504 0 .91-.406.91-.91v-3.75zm0 0"
        ></path>
        <path fill="#2D83D4" d="M7.293 7.813h15.625v4.69H7.293zm0 0"></path>
        <path fill="#2E70C9" d="M7.293 12.504h15.625v4.71H7.293zm0 0"></path>
        <path
          fill="#00488D"
          d="M11.625 17.707H2.957a.876.876 0 01-.875-.875V8.168c0-.484.395-.875.875-.875h8.668c.484 0 .875.39.875.875v8.664c0 .484-.39.875-.875.875zm0 0"
        ></path>
        <path
          fill="#FFF"
          d="M9.586 9.895L8.78 13.68l-.894-3.785H6.75l-.922 3.902L5 9.895H3.98l1.22 5.21h1.226l.89-4.007.895 4.007h1.172l1.222-5.21zm0 0"
        ></path>
      </g>
    </svg>
  );
}

export default WordIcon;
